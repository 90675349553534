<template>
  <div class="page-404">
    <!-- <img src="" alt="404 Image" /> -->
    <div class="svg">
    <e404 />
    </div>
    <div class="sec-title">{{ $t("pageNotFound") }}</div>
    <button class="btn-pri-dark mt-4" @click="action">
      {{ $t("backToHome") }}
    </button>
  </div>
</template>

<script>
import e404 from "../../assets/images/404.svg";
export default {
  components:{
    e404
  },
  methods: {
    action() {
      this.$router.push({ name: "Home" });
    },
  },
  // mounted(){
  //   document.querySelector('.app-header').style.display = 'none'
  //   document.querySelector('footer').style.display = 'none'
  // },
  // unmounted(){
  //       document.querySelector('.app-header').style.display = 'block'
  //   document.querySelector('footer').style.display = 'block'
  // }
};
</script>

<style lang="scss" scoped>
.page-404 {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .svg {
    width: 100%;
    max-width: 450px;
    margin-bottom: 40px;
  }
}
</style>
